import React from "react";
import "./styles/main.scss";
import iconWin from "./images/icon-win.svg";
import iconDate from "./images/icon-date.svg";
import iconLocation from "./images/icon-location.svg";
import iconPaddle from "./images/icon-pill/icon-pill-paddle-tennis.svg";
import iconBadminton from "./images/icon-pill/icon-pill-badminton.svg";
import iconTennis from "./images/icon-pill/icon-pill-tennis.svg";
import iconTableTennis from "./images/icon-pill/icon-pill-table-tennis.svg";
import iconSquash from "./images/icon-pill/icon-pill-squash.svg";
import iconRacquetball from "./images/icon-pill/icon-pill-racquetball.svg";
import iconPickleball from "./images/icon-pill/icon-pill-pickleball.svg";
import iconPadel from "./images/icon-pill/icon-pill-padel.svg";
import iconCourtTennis from "./images/icon-pill/icon-pill-court-tennis.svg";
import iconPlatformTennis from "./images/icon-pill/icon-pill-platform-tennis.svg";
import iconPaddleBall from "./images/icon-pill/icon-pill-paddle-ball.svg";
import Header from "./comp/Header";
import Footer from "./comp/Footer";
import ProfilePic from "./comp/ProfilePic";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import { getMessages } from "./internalization";
import { getGameData } from "./resource";
import imageArrow from "./images/ranking-arrow.svg";
import { parseDate } from "./utils";
import PoweredBy from "./comp/PowerIpm2";
import DownloadMaximize from "./comp/downloadMaximize";
import { TournamentName } from "./comp/TournamentName";

var SPORTSICON = {
  TENNIS: iconTennis,
  ROYALTENNIS: iconCourtTennis,
  PICKLEBALL: iconPickleball,
  SQUASH: iconSquash,
  COURTTENNIS: iconCourtTennis,
  PLATFORMTENNIS: iconPlatformTennis,
  TABLETENNIS: iconTableTennis,
  PADDLETENNIS: iconPaddle,
  PADEL: iconPadel,
  PADDLEBALL: iconPaddleBall,
  BADMINTON: iconBadminton,
  RACQUETBALL: iconRacquetball,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: props.language,
      game: null,
      initiatorSkill: null,
      opponentSkill: null,
      tournament: null,
      formatterDay: this.getDateFormat(props.language),
    };
    this.changeLanguage = this.changeLanguage.bind(this);
  }
  getDateFormat(language) {
    return new Intl.DateTimeFormat(language, { day: "2-digit", month: "short", year: "numeric" });
  }
  componentDidMount() {
    getGameData(this.props.idToken)
      .then((data) => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            game: data.data.game,
            initiatorSkill: data.data.initiatorSkill,
            opponentSkill: data.data.opponentSkill,
            tournament: data.data.tournament,
          });
        }, 0);
      })
      .catch(() => {
        window.location.href = "https://web.iplayme2.com";
      });
  }
  changeLanguage(language) {
    this.setState({ ...this.state, language, formatterDay: this.getDateFormat(language) });
  }
  getUserName(user) {
    if (user.lastName) {
      return user.firstName.substring(0, 1) + ". " + user.lastName;
    } else {
      return user.firstName;
    }
  }

  renderSet(setList, scoreVariable) {
    const result = [];
    for (let current = 0; current < setList.length; current++) {
      const next = current < setList.length - 1 ? current + 1 : current;
      if (setList[next].type === "TB") {
        result.push(
          <div className="game d-flex align-items-center justify-content-center" key={setList[current].id}>
            {setList[current][scoreVariable]}
            <span className="d-flex align-items-center justify-content-center">{setList[next][scoreVariable]}</span>
          </div>
        );
        current++;
      } else if (setList[current].type === "TBO" || setList[current].type === "SD") {
        result.push(
          <div className="game-tbo d-flex align-items-center justify-content-center" key={setList[current].id}>
            {setList[current][scoreVariable]}
          </div>
        );
      } else {
        result.push(
          <div className="game d-flex align-items-center justify-content-center" key={setList[current].id}>
            {setList[current][scoreVariable]}
          </div>
        );
      }
    }
    return result;
  }
  renderSetCount(gameSet) {
    let index = 0;
    return gameSet.map((item) => {
      if (item.type !== "TB") {
        index++;
        return (
          <div className="set m-2 text-center" key={item.id}>
            {index}
          </div>
        );
      }
    });
  }
  getProfilePicName(user) {
    if (!user.profileImageId) {
      if (user.type === "NORMAL") {
        return "UserAvatar.png";
      } else if (user.type === "CONTACT") {
        return "ContactAvatar.png";
      } else if (user.type === "PRECONTACT") {
        return "PreContactAvatar.png";
      }
    }
    return user.profileImageId;
  }

  render() {
    if (!this.state.game) return null;
    let dateS = "";
    try {
      dateS = this.state.formatterDay.format(parseDate(this.state.game.date));
    } catch (e) {}

    const profilePicBaseURL = this.props.config.profilePicture;

    let firstPartyTeam = null;
    let secondPartyTeam = null;
    let firstPartySetVar = null;
    let secondPartySetVar = null;
    let firstRank = null;
    let secondRank = null;
    if (this.state.game.winner === "INITIATOR") {
      firstPartyTeam = this.state.game.initiatorTeam;
      secondPartyTeam = this.state.game.opponentTeam;
      firstPartySetVar = "initiatorScore";
      secondPartySetVar = "opponentScore";
      firstRank = this.state.initiatorSkill;
      secondRank = this.state.opponentSkill;
    } else {
      firstPartyTeam = this.state.game.opponentTeam;
      secondPartyTeam = this.state.game.initiatorTeam;
      firstPartySetVar = "opponentScore";
      secondPartySetVar = "initiatorScore";
      firstRank = this.state.opponentSkill;
      secondRank = this.state.initiatorSkill;
    }
    let sport = this.state.game.sport;
    let isTIE = this.state.game.winner === "TIE";
    return (
      <IntlProvider
        locale={this.state.language}
        key={this.state.language}
        messages={getMessages(this.state.language.toLowerCase())}
      >
        <Header changeLanguage={this.changeLanguage} />
        <div className="container-match-result">
          <div className="container-fluid">
            <h1 className="text-center main-title">
              <FormattedMessage id="SC.MatchResult" />
            </h1>

            <div className="text-center">
              <div className="sport-pill">
                <img className="icon-pill" src={SPORTSICON[sport]} alt="Date" />
                <FormattedMessage id={`ENUM.Sport.${sport}`} />
              </div>
            </div>

            <div className="match-info-wrapper mt-5 mb-5">
              <div className="d-flex justify-content-center flex-column flex-md-row align-items-center mb-1">
                <div className="px-2">
                  <img src={iconDate} alt="Date" />
                  <span className="ml-2">{dateS}</span>
                </div>
                {this.state.game.location && (
                  <div className="px-2">
                    <img src={iconLocation} alt="Location" />
                    <span className="ml-2">{this.state.game.location.name}</span>
                  </div>
                )}
              </div>
              {this.state.tournament && (
                <div className="text-center">
                  <TournamentName detail={this.state.tournament} />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="box-match mx-auto p-md-2">
                  {firstRank.preIplayme2Rating && (
                    <div className="text-center d-flex justify-content-center p-3">
                      <div class="ranking text-center d-flex align-items-center">
                        <span class="valueP">
                          <FormattedNumber
                            value={firstRank.preIplayme2Rating}
                            minimumFractionDigits="1"
                            maximumFractionDigits="1"
                          />
                        </span>
                        <div className="d-flex rating-pill up">
                          <img src={imageArrow} alt="Arrow up" style={{ verticalAlign: "text-top" }} />
                          +
                          <FormattedNumber
                            value={firstRank.iplayme2Rating}
                            minimumFractionDigits="1"
                            maximumFractionDigits="1"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-between align-items-center px-1 px-md-4">
                    <div className="players d-flex flex-column justify-content-center">
                      {firstPartyTeam.players.map((user) => {
                        return (
                          <div className="player d-flex align-items-center" key={user.code}>
                            <ProfilePic url={this.getProfilePicName(user)} baseURL={profilePicBaseURL} />
                            <div className="name">{this.getUserName(user)}</div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="games d-flex align-items-center justify-content-center">
                      {this.renderSet(this.state.game.gameSet, firstPartySetVar)}
                      {!isTIE && (
                        <div className="win">
                          <img src={iconWin} alt="Win" />
                        </div>
                      )}
                    </div>
                  </div>

                  <hr></hr>

                  <div className="d-flex justify-content-between align-items-center px-1 px-md-4">
                    <div className="players d-flex flex-column justify-content-center">
                      {secondPartyTeam.players.map((user) => {
                        return (
                          <div className="player d-flex align-items-center" key={user.code}>
                            <ProfilePic url={this.getProfilePicName(user)} baseURL={profilePicBaseURL} />
                            <div className="name">{this.getUserName(user)}</div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="games d-flex align-items-center justify-content-center">
                      {this.renderSet(this.state.game.gameSet, secondPartySetVar)}
                      {!isTIE && <div className="win"></div>}
                    </div>
                  </div>

                  {secondRank.preIplayme2Rating && (
                    <div className="text-center d-flex justify-content-center p-3">
                      <div class="ranking text-center d-flex align-items-center">
                        <span class="valueN">
                          <FormattedNumber
                            value={secondRank.preIplayme2Rating}
                            minimumFractionDigits="1"
                            maximumFractionDigits="1"
                          />
                        </span>
                        <div className="d-flex rating-pill down">
                          <img
                            src={imageArrow}
                            alt="Arrow down"
                            style={{ verticalAlign: "text-bottom", transform: "rotate(180deg)" }}
                          />
                          <FormattedNumber
                            value={secondRank.iplayme2Rating}
                            minimumFractionDigits="1"
                            maximumFractionDigits="1"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <PoweredBy />
          <DownloadMaximize />
        </div>
        <Footer changeLanguage={this.changeLanguage} />
      </IntlProvider>
    );
  }
}

export default App;
